<template>
	<NotificationsLayout></NotificationsLayout>
</template>

<script>
import NotificationsLayout from "@/components/shared/notification/Layout.vue";

export default {
	name: "NotificationsPage",

	components: {
		NotificationsLayout,
	},
};
</script>
